<template>
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0312 18.75H16.25V0.9375C16.25 0.419727 15.8303 0 15.3125 0H2.1875C1.66973 0 1.25 0.419727 1.25 0.9375V18.75H0.46875C0.209883 18.75 0 18.9599 0 19.2188V20H17.5V19.2188C17.5 18.9599 17.2901 18.75 17.0312 18.75ZM5 2.96875C5 2.70988 5.20988 2.5 5.46875 2.5H7.03125C7.29012 2.5 7.5 2.70988 7.5 2.96875V4.53125C7.5 4.79012 7.29012 5 7.03125 5H5.46875C5.20988 5 5 4.79012 5 4.53125V2.96875ZM5 6.71875C5 6.45988 5.20988 6.25 5.46875 6.25H7.03125C7.29012 6.25 7.5 6.45988 7.5 6.71875V8.28125C7.5 8.54012 7.29012 8.75 7.03125 8.75H5.46875C5.20988 8.75 5 8.54012 5 8.28125V6.71875ZM7.03125 12.5H5.46875C5.20988 12.5 5 12.2901 5 12.0312V10.4688C5 10.2099 5.20988 10 5.46875 10H7.03125C7.29012 10 7.5 10.2099 7.5 10.4688V12.0312C7.5 12.2901 7.29012 12.5 7.03125 12.5ZM10 18.75H7.5V15.4688C7.5 15.2099 7.70988 15 7.96875 15H9.53125C9.79012 15 10 15.2099 10 15.4688V18.75ZM12.5 12.0312C12.5 12.2901 12.2901 12.5 12.0312 12.5H10.4688C10.2099 12.5 10 12.2901 10 12.0312V10.4688C10 10.2099 10.2099 10 10.4688 10H12.0312C12.2901 10 12.5 10.2099 12.5 10.4688V12.0312ZM12.5 8.28125C12.5 8.54012 12.2901 8.75 12.0312 8.75H10.4688C10.2099 8.75 10 8.54012 10 8.28125V6.71875C10 6.45988 10.2099 6.25 10.4688 6.25H12.0312C12.2901 6.25 12.5 6.45988 12.5 6.71875V8.28125ZM12.5 4.53125C12.5 4.79012 12.2901 5 12.0312 5H10.4688C10.2099 5 10 4.79012 10 4.53125V2.96875C10 2.70988 10.2099 2.5 10.4688 2.5H12.0312C12.2901 2.5 12.5 2.70988 12.5 2.96875V4.53125Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#616161"
    }
  }
};
</script>
