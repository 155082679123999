<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4112 0.701875C18.1991 0.193438 16.9859 3.75862e-08 15.7734 3.75862e-08C11.9244 -0.000312462 8.07562 1.94812 4.22656 1.94812C3.26125 1.94812 2.29656 1.82563 1.33125 1.51938C1.22281 1.485 1.11406 1.46875 1.00781 1.46875C0.47 1.46875 0 1.885 0 2.46281V12.3772C0 12.7719 0.225938 13.1459 0.58875 13.2978C1.80094 13.8066 3.01406 14 4.22656 14C8.07562 14 11.9247 12.0516 15.7738 12.0516C16.7391 12.0516 17.7037 12.1741 18.6691 12.4803C18.7775 12.5147 18.8862 12.5309 18.9925 12.5309C19.5303 12.5309 20.0003 12.1147 20.0003 11.5369V1.62281C20 1.22781 19.7741 0.854063 19.4112 0.701875ZM10 10C8.61906 10 7.5 8.65656 7.5 7C7.5 5.34312 8.61938 4 10 4C11.3806 4 12.5 5.34312 12.5 7C12.5 8.65719 11.3803 10 10 10Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#616161"
    }
  }
};
</script>
